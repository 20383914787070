import React, { useEffect } from 'react';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from './_store';
import {
  getUserPending, getTokenPending, setUserName
} from './_store/actions/user.actions';
import UserService from './services/UserService';
import Router from './router/Router';
import { routes } from './router/config';
import { useInterval } from './hooks/useInterval';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: IStore) => state.user.token);

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      dispatch(getUserPending());
    }

    dispatch(getTokenPending());
    const userName = UserService.getUsername();
    dispatch(setUserName(userName));
  }, []);

  useInterval(() => {
    dispatch(getTokenPending());
  }, 1000 * 60 * 20);


  return token ? (
    <div className='app' id={ process?.env.REACT_APP_NAME }>
      <Router routes={routes} />
    </div>
  ) : null;
};

export default App;
