import { createTypedAction } from 'redux-actions-ts';
import { IUser } from '../types/user.types';

export const getUserPending = createTypedAction<void>('[Pending] Получить вошедшего пользователя');
export const getUserSuccess = createTypedAction<IUser>('[Success] Получить вошедшего пользователя');

export const getTokenPending = createTypedAction<void>('[Pending] Получить токен');
export const getTokenSuccess = createTypedAction<string>('[Success] Получить токен');

export const setUserName = createTypedAction<string>('Установить имя вошедшего пользователя');
