import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';

import UserService from './services/UserService';
import intercept from './api/interceptor';
// import axios from 'axios';

import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './_store';

import cssVars from 'css-vars-ponyfill';
import variables from './api/colors.json';

intercept();

/** Подключаем цвета для IE */
cssVars({
  watch: true,
  include: 'style,link[rel="stylesheet"]:not([href*="//"])',
  variables
});

const renderApp = () => ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <App/>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

UserService.initKeycloak(renderApp);
// intercept(axios);
