export const mocksWP = [
  {
    'externId': '4ccd-8f05-5186058b305f-00151fe2',
    'objnroo': 'I500000000000000014115',
    'occposition': '128.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': [
      {
        'id': 'TN00001407',
        'fullName': 'Джорди Альба',
        'lastName': 'Альба',
        'firstName': 'Джорди',
        'middleName': '',
        'plans': '0',
        'plansName': 'Должность тест',
        'orgeh': '0',
        'orgehName': 'Организация тест',
        'email': 'Email тест',
        'dateFrom': '2021-07-02',
        'dateTo': '2021-07-02'
      },
      {
        'id': 'TN00001660',
        'fullName': 'Ирина Большунова',
        'lastName': 'Большунова',
        'firstName': 'Ирина',
        'middleName': '',
        'plans': '0',
        'plansName': 'Должность тест',
        'orgeh': '0',
        'orgehName': 'Организация тест',
        'email': 'Email тест',
        'dateFrom': '2021-07-05',
        'dateTo': '2021-07-05'
      }
    ]
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fdc',
    'objnroo': 'I500000000000000014115',
    'occposition': '128.02',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00152006',
    'objnroo': 'I500000000000000014115',
    'occposition': '128.03',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00152000',
    'objnroo': 'I500000000000000014115',
    'occposition': '128.04',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014115',
    'occposition': '128.05',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014115',
    'occposition': '128.06',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014115',
    'occposition': '128.07',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014115',
    'occposition': '128.08',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014120',
    'occposition': '133.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014120',
    'occposition': '133.02',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014120',
    'occposition': '133.03',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014120',
    'occposition': '133.04',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014140',
    'occposition': '96.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014140',
    'occposition': '96.02',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014140',
    'occposition': '96.03',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014140',
    'occposition': '96.04',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014142',
    'occposition': '98.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014144',
    'occposition': '127A.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014144',
    'occposition': '127A.02',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014145',
    'occposition': '100.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014145',
    'occposition': '100.02',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014145',
    'occposition': '100.03',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014145',
    'occposition': '100.04',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014145',
    'occposition': '100.05',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014159',
    'occposition': '114.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014159',
    'occposition': '114.02',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014159',
    'occposition': '114.03',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014164',
    'occposition': '123.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014165',
    'occposition': '122.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014166',
    'occposition': '121.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.01',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.02',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.03',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.04',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.05',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.06',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.07',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.08',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.09',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  },
  {
    'externId': '4ccd-8f05-5186058b305f-00151fde',
    'objnroo': 'I500000000000000014173',
    'occposition': '115.10',
    'kindWp': '',
    'planind': '',
    'GetTabEmpl': []
  }
];
