import Axios from 'axios-observable';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logRecord } from 'root-front';
import { logins } from './logins';

/** Interceptors */
const intercept = () => {
  Axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.headers.hasOwnProperty('NOINTERCEPT')) {
      return config;
    }

    config.headers['Accept-Language'] = 'ru-RU, ru;q=0.9, en-US;q=0.8, en';
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Expires'] = '-1';

    // Авторизация на дев стенде
    if (process?.env.REACT_APP_ENV === 'dev') {
      for (const key in logins) {
        ~key.indexOf('+') && (config.headers.Authorization = logins[key]);
      }
    }

    // const host = ~window.location.hostname.indexOf('127.0.') ? 'https://sapd-fes-ap01.vtb24.ru:44310/' : '/';
    const isLocalHost = ~window.location.hostname.indexOf('127.0.') || ~window.location.hostname.indexOf('localhost');
    let host = 'https://wp-booking-service.innotrips.ru/';

    if (isLocalHost) {
      host = 'https://wp-booking-service.innotrips.ru/';

      // if (config?.url && config.url.includes('schema')) {
      //   host = 'http://localhost:8095/';
      // } else {
      //   host = 'https://sapd-fes-ap01.vtb24.ru:44310/';
      // }
    }

    config.url = host + config.url;

    if (process?.env.REACT_APP_LOCAL === '-1') {
      config = changeConfig(config);
    }

    logRecord({
      timestamp: Date.now(),
      source: 'network',
      snapshot: config
    });
    return config;
  });

  Axios.interceptors.response.use((response: AxiosResponse) => {
    logRecord({
      timestamp: Date.now(),
      source: 'network',
      snapshot: response
    });
    return response;
  });
};

const changeConfig = (config: AxiosRequestConfig) => {
  const obj = {
    method: config.method,
    url: config.url,
    auth: config.headers.Authorization,
    data: config.data,
    cache: config.method === 'get'
  };

  config.data = obj;
  config.method = 'post';
  config.url = 'http://127.0.0.1:3000/getData';
  config.headers['Access-Control-Allow-Origin'] = '*';

  return config;
};

export default intercept;
