import Keycloak from 'keycloak-js';
import { keycloak } from '../keycloak';
// @ts-ignore
const _kc = new Keycloak(keycloak);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'check-sso',
    checkLoginIframe: true,
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }

      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

// const doLoadUserInfo = _kc.loadUserInfo();

const getToken = () => _kc.token;

const getIdToken = () => _kc.idToken;
const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getUserEmail = () => _kc.tokenParsed?.email;
const getUserEmployeeId = () => _kc.tokenParsed?.employeeId;
console.log(_kc);

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getIdToken,
  updateToken,
  getUsername,
  getUserEmail,
  getUserEmployeeId,
  hasRole,
};

export default UserService;
