import {
  delay, map, retryWhen, take
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import Axios from 'axios-observable';
import { AxiosResponse } from 'axios';
import { IUser } from '../types/user.types';

/** Получить вошедшего пользователя */
export const getUser = (): Observable<IUser> => {
  return Axios.get(
    'sap/opu/odata4/sap/zhrbc/default/sap/zhrbc_0720_react_utils/0001/IUser(%270%27)',
    {
      headers: {
        'X-CSRF-Token': 'Fetch',
        'cacheURL': true
      }
    }
  ).pipe(map((data: AxiosResponse<IUser>) => {
    return {
      ...data.data,
      token: data.headers['x-csrf-token'] as string
    };
  }));
};

export const getToken = (): Observable<string> => {

  if (process.env['REACT_APP_LOCAL'] === '0') {
    return of('mockToken').pipe(delay(0), map((data: string) => data));
  }

  return Axios.get(
    'sap/opu/odata4/sap/zlocal_service/default/sap/zhrbc_0720_react_utils/0001/IXCSRFToken',
    { headers: { 'X-CSRF-Token': 'Fetch' } }
  )
    .pipe(
      map((response) => response.headers['x-csrf-token'] as string),
      retryWhen((errors) => errors.pipe(delay(2000), take(5))),
    );
};
