import { Action } from 'redux-actions';
import { createTypedHandler, handleTypedActions } from 'redux-actions-ts';
import { IUser } from '../types/user.types';
import {
  getUserSuccess, getTokenSuccess, setUserName
} from '../actions/user.actions';

export interface IUserState {
  user: IUser | null;
  userLoaded: boolean;
  userName: string;
  token: string;
}

const initialState: IUserState = {
  user: {
    id: '#000009',
    firstName: 'Георгий',
    lastName: 'Кутателадзе',
    middleName: 'Тамазиевич',
    fullName: 'Кутателадзе Георгий Тамазиевич',
    position: 'The Deceiver',
    positionId: 'FE 1',
    department: 'Департамент с очень длинным, непонятным и неочевидным названием, в котором могут быть еще семьдесят пять тысяч отделов разной важности',
    departmentId: '11',
    departmentsPath: [
      {
        id: 'o1',
        name: 'Name 1',
        unitType: 'Unit type 1',
        unitTypeDesc: 'Unit description 1'
      },
      {
        id: 'o2',
        name: 'Name 2',
        unitType: 'Unit type 2',
        unitTypeDesc: 'Unit description 2'
      },
      {
        id: 'o3',
        name: 'Name 3',
        unitType: 'Unit type 3',
        unitTypeDesc: 'Unit description 3'
      },
      {
        id: 'o4',
        name: 'Name 4',
        unitType: 'Unit type 4',
        unitTypeDesc: 'Unit description 4'
      },
      {
        id: 'o5',
        name: 'Name 5',
        unitType: 'Unit type 5',
        unitTypeDesc: 'Unit description 5'
      },
      {
        id: 'o6',
        name: 'Name 6',
        unitType: 'Unit type 6',
        unitTypeDesc: 'Unit description 6'
      },
      {
        id: 'o7',
        name: 'Name 7',
        unitType: 'Unit type 7',
        unitTypeDesc: 'Unit description 7'
      }
    ],
    photo: ''
  },
  userLoaded: false,
  userName: '',
  token: ''
};

const userReducer = handleTypedActions(
  [
    /** Получить вошедшего пользователя */
    createTypedHandler(getUserSuccess, (state: IUserState, action: Action<IUser>): IUserState => {
      return {
        ...state,
        user: action.payload,
        userLoaded: true
      };
    }),
    /** Полуить токен */
    createTypedHandler(getTokenSuccess, (state: IUserState, action: Action<string>): IUserState => {

      return {
        ...state,
        token: action.payload || ''
      };
    }),
    /** Установить имя вошедшего пользователя */
    createTypedHandler(setUserName, (state: IUserState, action: Action<string>): IUserState => {

      return {
        ...state,
        userName: action.payload || ''
      };
    }),
  ],
  initialState
);

export default userReducer;
