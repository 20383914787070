import { Action } from 'redux-actions';
import { createTypedHandler, handleTypedActions } from 'redux-actions-ts';

import {
  getRoomSchemaSuccess,
  getRoomsListSuccess,
  setActiveDates,
  getDepartmentsSuccess,
  setDateRanges,
  updateRoomsListSuccess,
  setSelectedDate,
  setSelectedPlace,
  getBookingsSuccess,
  setVisionMode,
  setCreateBookingStatus,
  deleteBookingSuccess,
  setDeleteBookingStatus,
  setSelectedBookingId,
  getBookingsByDaySuccess
} from '../actions/rooms.actions';
import {
  IRoom, IRoomSchema, IDepartment, IBookingByDay
} from '../types/rooms.types';
import { mocksWP } from '../mocks/mocksWP';
import moment from 'moment';
import { IUser } from '../types/user.types';

export interface IRoomsState {
  schema: File | undefined;
  schemaLoaded: boolean;
  rooms: IRoom[];
  roomsLoaded: boolean;
  occupation: any[];
  activeDates: string[];
  usersMap: Record<string, IUser>;
  workPlaceMap: Record<string, any>;
  departments: any[];
  dateRanges: any[];
  bookings: any[];
  selectedDate: string;
  selectedPlace: string;
  departmentsBooking: IDepartment[];
  visionMode: string;
  createBookingStatus: boolean;
  deleteBookingStatus: boolean;
  selectedBookingId: string;
  bookingsByDay: IBookingByDay[];
}

const initialState: IRoomsState = {
  schema: undefined,
  schemaLoaded: false,
  rooms: [],
  roomsLoaded: false,
  occupation: mocksWP,
  activeDates: [],
  usersMap: {},
  workPlaceMap: {},
  departments: [],
  dateRanges: [],
  bookings: [],
  selectedDate: '',
  selectedPlace: '',
  departmentsBooking: [],
  visionMode: 'schema',
  createBookingStatus: false,
  deleteBookingStatus: false,
  selectedBookingId: '',
  bookingsByDay: null
};

const roomsReducer = handleTypedActions(
  [
    /** Получить план помещения */
    createTypedHandler(getRoomSchemaSuccess, (state: IRoomsState, action: Action<IRoomSchema>): IRoomsState => {
      return {
        ...state,
        // @ts-ignore
        // eslint-ignore
        schema: action.payload.file,
        // usersMap,
        // workPlaceMap,
        schemaLoaded: true
      };
    }),
    /** Получить список помещений */
    createTypedHandler(getRoomsListSuccess, (state: IRoomsState, action: Action<any>): IRoomsState => {
      let data = [];
      let workPlace = [];

      if (action.payload.length > 1) {
        data = action.payload.map(el => ({
          externId: el.externalId,
          GetTabEmpl: el.reservations.map(item => ({
            id: item.employee,
            dateFrom: item.bookedFrom,
            dateTo: item.bookedTo
          }))
        }));
        const { workPlaceMap } = extractMaps(data);
        workPlace = workPlaceMap;
      }

      return {
        ...state,
        rooms: [],
        roomsLoaded: true,
        workPlaceMap: workPlace,
        bookings: action.payload
      };
    }),
    /** Установить активные даты */
    createTypedHandler(setActiveDates, (state: IRoomsState, action: Action<string[]>): IRoomsState => {
      return {
        ...state,
        activeDates: action.payload
      };
    }),
    /** Установить выбранное бронирование */
    createTypedHandler(setSelectedBookingId, (state: IRoomsState, action: Action<string>): IRoomsState => {
      return {
        ...state,
        selectedBookingId: action.payload
      };
    }),
    /** Получить список подразделений */
    createTypedHandler(getDepartmentsSuccess, (state: IRoomsState, action: Action<IDepartment[]>): IRoomsState => {
      return {
        ...state,
        departments: action.payload
      };
    }),
    /** Установить диапазоны дат */
    createTypedHandler(setDateRanges, (state: IRoomsState, action: Action<any[]>): IRoomsState => {
      return {
        ...state,
        dateRanges: action.payload
      };
    }),
    /** Обновить список помещений */
    createTypedHandler(updateRoomsListSuccess, (state: IRoomsState): IRoomsState => {
      let updatedRanges = [];

      if (state.dateRanges?.length) {
        updatedRanges = [...state.dateRanges];
        updatedRanges.shift();
      }

      return {
        ...state,
        dateRanges: updatedRanges,
        createBookingStatus: true
      };
    }),
    /** Установить выбранную дату */
    createTypedHandler(setSelectedDate, (state: IRoomsState, action: Action<string>): IRoomsState => {
      return {
        ...state,
        selectedDate: action.payload
      };
    }),
    /** Установить выбранное место */
    createTypedHandler(setSelectedPlace, (state: IRoomsState, action: Action<string>): IRoomsState => {
      return {
        ...state,
        selectedPlace: action.payload
      };
    }),
    /** Получить список бронирований */
    createTypedHandler(getBookingsSuccess, (state: IRoomsState, action: Action<IDepartment[]>): IRoomsState => {
      return {
        ...state,
        departmentsBooking: action.payload
      };
    }),
    /** Установить режим просмотра */
    createTypedHandler(setVisionMode, (state: IRoomsState, action: Action<string>): IRoomsState => {
      return {
        ...state,
        visionMode: action.payload
      };
    }),
    /** Установить статус создания заявки */
    createTypedHandler(setCreateBookingStatus, (state: IRoomsState, action: Action<boolean>): IRoomsState => {
      return {
        ...state,
        createBookingStatus: action.payload
      };
    }),
    /** Установить статус удаления заявки */
    createTypedHandler(setDeleteBookingStatus, (state: IRoomsState, action: Action<boolean>): IRoomsState => {
      return {
        ...state,
        deleteBookingStatus: action.payload
      };
    }),
    /** Удалить бронирование */
    createTypedHandler(deleteBookingSuccess, (state: IRoomsState): IRoomsState => {
      return {
        ...state,
        deleteBookingStatus: true
      };
    }),
    /** Получить список бронирований на день */
    createTypedHandler(getBookingsByDaySuccess, (state: IRoomsState, action: Action<IBookingByDay[]>): IRoomsState => {
      return {
        ...state,
        bookingsByDay: action.payload
      };
    }),
  ],
  initialState
);

export default roomsReducer;

function extractMaps (occupation: any) {
  const usersMap: any = {};

  const workPlaceMap: any = occupation.reduce((acc: any, e: any) => {
    acc[e.externId] = {};
    const datesMap: any = {};

    // [1] Собрать юзеров в мапу
    e.GetTabEmpl.forEach((user: any) => {
      if (usersMap[user.id] === undefined) {
        usersMap[user.id] = user;
      }

      // [2] Собрать даты в массив дат
      let dateFrom = user.dateFrom;

      if (datesMap[dateFrom] === undefined) {
        datesMap[dateFrom] = user.id;
      }

      while (dateFrom !== user.dateTo) {
        dateFrom = moment(dateFrom, 'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD');

        if (datesMap[dateFrom] === undefined) {
          datesMap[dateFrom] = user.id;
        }
      }

    });

    acc[e.externId] = {
      info: e,
      dates: datesMap
    };
    return acc;
  }, {});

  return {
    usersMap,
    workPlaceMap
  };
}
