import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    const tick = () => {
      callbackRef.current();
    };

    if (delay) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [callback, delay]);
};
