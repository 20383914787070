import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Axios from 'axios-observable';
import { AxiosResponse } from 'axios';
import {
  IRoomSchema, IRoomsRequest, IDepartment, IBookingsRequest, IBookingByDay
} from '../types/rooms.types';


/** Получить план помещения */
export const getRoomSchema = (): Observable<IRoomSchema> => {
  const url = 'booking/schema';

  return Axios.get(url).pipe(map(({ data }: AxiosResponse<any>) => {
    const blob = b64toBlob(data);

    return { file: new File([blob], 'schema') };
  }));
};

function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

/** Получить список помещений */
export const getRoomsList = (): Observable<any> => {
  const url = 'booking';
  return Axios.get(url).pipe(map(({ data }: AxiosResponse<any>) => data));
};

/** Обновить список помещений */
export const updateRoomsList = (payload: IRoomsRequest): Observable<any> => {
  const url = 'booking';
  return Axios.post(url, payload).pipe(map(({ data }: AxiosResponse<any>) => data));
};

/** Получить список подразделений */
export const getDepartments = (): Observable<IDepartment[]> => {
  const url = 'booking/org-unit';
  return Axios.get(url).pipe(map(({ data }: AxiosResponse<IDepartment[]>) => data));
};

/** Получить список бронирований */
export const getBookings = (payload: IBookingsRequest): Observable<IDepartment[]> => {
  const url = 'booking/reservations-by-org-unit';
  return Axios.post(url, payload).pipe(map(({ data }: AxiosResponse<IDepartment[]>) => data));
};

/** Получить список бронирований */
export const deleteBooking = (payload: string): Observable<void> => {
  const url = `booking/${payload}`;
  return Axios.delete(url).pipe(map(({ data }: AxiosResponse<any>) => data));
};

/** Получить список бронирований на день */
export const getBookingsByDay = (payload: IBookingsRequest): Observable<IBookingByDay[]> => {
  const url = 'booking/reservations-by-day';
  return Axios.post(url, payload).pipe(map(({ data }: AxiosResponse<IBookingByDay[]>) => data));
};
