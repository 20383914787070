// import React, { Suspense } from 'react';
// import { Redirect, Route } from 'react-router-dom';
// import { IRoute } from './config';
// import UserService from '../services/UserService';
//
// const RouteWithSubRoutes = (route: IRoute) => {
//   const authenticated = UserService.isLoggedIn();
//
//   const renderRoute = (route: IRoute, props: any) => {
//     if (route.redirect) {
//       return <Redirect to={route.redirect} />;
//     }
//
//     if ((route.private && authenticated) || !route.private) {
//       return route.component && <route.component {...props} routes={route.routes} />;
//     }
//
//     return <div>Redirect Here When Not Authenticated</div>;
//   };
//
//   return (
//     <Suspense fallback={route.fallback}>
//       <Route path={route.path} render={(props: any) => renderRoute(route, props)} />
//     </Suspense>
//   );
// };
//
// export default RouteWithSubRoutes;
//


import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IRoute } from './config';
import UserService from '../services/UserService';

const RouteWithSubRoutes = (route: IRoute) => {
  const authenticated = UserService.isLoggedIn();

  const renderRoute = (route: IRoute, props: any) => {
    if (route.redirect) {
      return <Redirect to={route.redirect} />;
    }

    if (route.private && authenticated || !route.private && !authenticated) {
      return route.component && <route.component {...props} routes={route.routes} />;
    }

    if (route.path === '/registration' && authenticated) {
      return <Redirect to='/home' />;
    }

    UserService.doLogin();

    return <></>;
  };

  return (
    <Suspense fallback={route.fallback}>
      <Route path={route.path} render={(props: any) => renderRoute(route, props)} />
    </Suspense>
  );
};

export default RouteWithSubRoutes;
