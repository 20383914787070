import { ActionsObservable, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import {
  catchError, map, switchMap
} from 'rxjs/operators';
import { Action } from 'redux-actions';
import { showErrorMessage } from '../_commonActions/error.actions';

import {
  getRoomSchema, getRoomsList, updateRoomsList, getDepartments, getBookings, deleteBooking, getBookingsByDay
} from '../services/rooms.services';
import {
  getRoomSchemaPending,
  getRoomSchemaSuccess,
  getRoomsListPending,
  getRoomsListSuccess,
  updateRoomsListPending,
  updateRoomsListSuccess,
  getDepartmentsPending,
  getDepartmentsSuccess,
  getBookingsPending,
  getBookingsSuccess,
  deleteBookingPending,
  deleteBookingSuccess,
  getBookingsByDayPending,
  getBookingsByDaySuccess
} from '../actions/rooms.actions';
import {
  IRoomSchema, IRoomsRequest, IDepartment, IBookingsRequest, IBookingByDay
} from '../types/rooms.types';

/** Получить план помещения */
export const getRoomSchemaEffect$ = (actions$: ActionsObservable<Action<void>>) =>
  actions$.pipe(
    ofType(getRoomSchemaPending.toString()),
    switchMap(() =>
      getRoomSchema().pipe(
        map((result: IRoomSchema) => getRoomSchemaSuccess(result)),
        catchError(showErrorMessage)
      ))
  );


/** Получить список помещений */
export const getRoomsListEffect$ = (actions$: ActionsObservable<Action<void>>) =>
  actions$.pipe(
    ofType(getRoomsListPending.toString()),
    switchMap(() =>
      getRoomsList().pipe(
        map((result: IRoomSchema) => getRoomsListSuccess(result)),
        catchError(showErrorMessage)
      ))
  );

/** Обновить список помещений */
export const updateRoomsListEffect$ = (actions$: Observable<Action<IRoomsRequest>>) =>
  actions$.pipe(
    ofType(updateRoomsListPending.toString()),
    switchMap(({ payload }) =>
      updateRoomsList(payload).pipe(
        map((result: any) => updateRoomsListSuccess(result)),
        catchError(showErrorMessage)
      ))
  );

/** Получить список департаментов */
export const getDepartmentsEffect$ = (actions$: ActionsObservable<Action<IDepartment[]>>) =>
  actions$.pipe(
    ofType(getDepartmentsPending.toString()),
    switchMap(() =>
      getDepartments().pipe(
        map((result: IDepartment[]) => getDepartmentsSuccess(result)),
        catchError(showErrorMessage)
      ))
  );

/** Получить список бронирований */
export const getBookingsEffect$ = (actions$: ActionsObservable<Action<IBookingsRequest>>) =>
  actions$.pipe(
    ofType(getBookingsPending.toString()),
    switchMap(({ payload }) =>
      getBookings(payload).pipe(
        map((result: IDepartment[]) => getBookingsSuccess(result)),
        catchError(showErrorMessage)
      ))
  );

/** Получить список бронирований */
export const deleteBookingEffect$ = (actions$: ActionsObservable<Action<string>>) =>
  actions$.pipe(
    ofType(deleteBookingPending.toString()),
    switchMap(({ payload }) =>
      deleteBooking(payload).pipe(
        map((result: any) => deleteBookingSuccess(result)),
        catchError(showErrorMessage)
      ))
  );

/** Получить список бронирований на день */
export const getBookingsByDayEffect$ = (actions$: ActionsObservable<Action<IBookingsRequest>>) =>
  actions$.pipe(
    ofType(getBookingsByDayPending.toString()),
    switchMap(({ payload }) =>
      getBookingsByDay(payload).pipe(
        map((result: IBookingByDay[]) => getBookingsByDaySuccess(result)),
        catchError(showErrorMessage)
      ))
  );
