import { combineEpics, createEpicMiddleware } from 'redux-observable';
import {
  applyMiddleware, combineReducers, createStore
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';


import { getTokenEffect$, getUserEffect$ } from './effects/user.effects';
import userReducer, { IUserState } from './reducers/user.reducer';


import { reduxLogMiddleware } from 'root-front';

import {
  getRoomSchemaEffect$,
  getRoomsListEffect$,
  updateRoomsListEffect$,
  getDepartmentsEffect$,
  getBookingsEffect$,
  deleteBookingEffect$,
  getBookingsByDayEffect$
} from './effects/rooms.effects';
import roomsReducer, { IRoomsState } from './reducers/rooms.reducer';

/* [imports:end] */

export interface IStore {
  user: IUserState;
  rooms: IRoomsState;

  /* [types:end] */
}

const observableMiddleware = createEpicMiddleware();

/** Register reducers */
export const reducers = combineReducers({
  user: userReducer,
  rooms: roomsReducer,

  /* [reducers:end] */
});

/** Create store */
export const store = createStore(reducers, composeWithDevTools(applyMiddleware(observableMiddleware, reduxLogMiddleware)));

/** Register effects */
// @ts-ignore
observableMiddleware.run(combineEpics(
  getUserEffect$,
  getTokenEffect$,
  getRoomSchemaEffect$,
  getRoomsListEffect$,
  updateRoomsListEffect$,
  getDepartmentsEffect$,
  getBookingsEffect$,
  deleteBookingEffect$,
  getBookingsByDayEffect$
  /* [effects:end] */
// eslint-disable-next-line function-paren-newline
));
