import { createTypedAction } from 'redux-actions-ts';
import {
  IRoomSchema, IRoomsRequest, IDepartment, IBookingsRequest, IBookingByDay
} from '../types/rooms.types';

export const getRoomSchemaPending = createTypedAction<void>('[Pending] Получить план помещения');
export const getRoomSchemaSuccess = createTypedAction<IRoomSchema>('[Success] Получить план помещения');

export const getRoomsListPending = createTypedAction<void>('[Pending] Получить список помещений');
export const getRoomsListSuccess = createTypedAction<any>('[Success] Получить список помещений');

export const setActiveDates = createTypedAction<string[]>('Установить активные даты');

export const updateRoomsListPending = createTypedAction<IRoomsRequest>('[Pending] Обновить список помещений');
export const updateRoomsListSuccess = createTypedAction<any>('[Success] Обновить список помещений');

export const getDepartmentsPending = createTypedAction<void>('[Pending] Получить список подразделений');
export const getDepartmentsSuccess = createTypedAction<IDepartment[]>('[Success] Получить список подразделений');

export const setDateRanges = createTypedAction<any[]>('Установить диапазоны дат');

export const setSelectedDate = createTypedAction<string>('Установить выбранную дату');

export const setSelectedPlace = createTypedAction<string>('Установить выбранное место');

export const setSelectedBookingId = createTypedAction<string>('Установить выбранное бронирование');

export const getBookingsPending = createTypedAction<IBookingsRequest>('[Pending] Получить список бронирований');
export const getBookingsSuccess = createTypedAction<IDepartment[]>('[Success] Получить список бронирований');

export const setVisionMode = createTypedAction<string>('Установить режим просмотра');

export const setCreateBookingStatus = createTypedAction<boolean>('Установить статус создания заявки');
export const setDeleteBookingStatus = createTypedAction<boolean>('Установить статус создания заявки');

export const deleteBookingPending = createTypedAction<string>('[Pending] Удалить бронирование');
export const deleteBookingSuccess = createTypedAction<void>('[Success] Удалить бронирование');

export const getBookingsByDayPending = createTypedAction<IBookingsRequest>('[Pending] Получить список бронирований на день');
export const getBookingsByDaySuccess = createTypedAction<IBookingByDay[]>('[Success] Получить список бронирований на день');
