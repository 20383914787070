import {
  ComponentType, LazyExoticComponent, ReactNode, lazy
} from 'react';


export interface IRoute {
  path: string;
  exact: boolean;
  private?: boolean;
  component?: LazyExoticComponent<ComponentType<any>>;
  routes?: IRoute[];
  redirect?: string;
  fallback: NonNullable<ReactNode> | null;
}


export const routes: IRoute[] = [
  {
    path: '/',
    redirect: '/home',
    exact: true,
    private: true,
    fallback: <div />
  },
  /** Главная список ЗК */
  {
    path: '/home',
    component: lazy(() => {
      return import('../components/organisms/MainContent');
    }),
    exact: false,
    private: true,
    fallback: <div />,
    routes: []

  },
];
