import { createTypedAction } from 'redux-actions-ts';
import { of } from 'rxjs';
import { AxiosError } from 'axios';
import { sendNotification } from 'root-front';

export const errorAction = createTypedAction<void>('[Error]');

export const showErrorMessage = (e: AxiosError) => {
  if (typeof e === 'string') {
    sendNotification({
      message: new Error(e).message,
      variant: 'danger'
    });
  } else {
    try {
      if (e.response) {
        e.response.data.error.details.filter((item: any) => item.message !== '').forEach((item: any, i: number) => {
          if (item['@SAP__common.Severity'] === 'error') {
            const id = item.code ? item.code + Date.now() : i + Date.now();
            sendNotification({
              id,
              message: item.message,
              variant: 'danger'
            });
          }
        });
      }
    } catch (e) {
      sendNotification({
        message: 'Проблема с сервером, попробуйте зайти позже.',
        variant: 'danger'
      });
    }
  }

  return of(errorAction());
};
