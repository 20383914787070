import { ActionsObservable, ofType } from 'redux-observable';
import {
  catchError, map, switchMap
} from 'rxjs/operators';
import { Action } from 'redux-actions';
import { showErrorMessage } from '../_commonActions/error.actions';
import { IUser } from '../types/user.types';
import { getUser, getToken } from '../services/user.services';
import {
  getUserPending, getUserSuccess, getTokenPending, getTokenSuccess
} from '../actions/user.actions';
import { Observable } from 'rxjs';

/** Получить вошедшего пользователя */
export const getUserEffect$ = (actions$: ActionsObservable<Action<void>>) =>
  actions$.pipe(
    ofType(getUserPending.toString()),
    switchMap(() =>
      getUser().pipe(
        map((result: IUser) => getUserSuccess(result)),
        catchError(showErrorMessage)
      ))
  );

/** Полуить токен */
export const getTokenEffect$ = (actions$: Observable<Action<void>>) =>
  actions$.pipe(
    ofType(getTokenPending.toString()),
    switchMap(() =>
      getToken().pipe(map((result: string) => {
        !result && console.error('НЕ ПРИШЕЛ ТОКЕН');
        return getTokenSuccess(result || 'token');
      })))
  );
